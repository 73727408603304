<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-tag-multiple</v-icon>
        Tags Groups
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title>
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn
              align="center"
              color="xbColor"
              class="white--text"
              @click="addTagGroup"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="tagGroups"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
              @click:row="editTagGroup"
            >
              <template #item.data_inicio="{ item }">
                {{ item.data_inicio | dateFormat("dd/MM/yyyy") }}
              </template>

              <template #item.data_fim="{ item }">
                {{ item.data_fim | dateFormat("dd/MM/yyyy") }}
              </template>

              <template #item.dashboard="{ item }">
                <v-icon v-if="item.dashboard" color="xbColor">
                  mdi-check-circle
                </v-icon>

                <v-icon v-else color="error"> mdi-minus-circle </v-icon>
              </template>

              <template #item.entraram="{ item }">
                <v-chip
                  v-if="item.entraram > 0"
                  small
                  color="xbColor"
                  label
                  text-color="white"
                  class="font-weight-bold"
                >
                  <v-icon left> mdi-account-arrow-down </v-icon>
                  {{ item.entraram }}
                </v-chip>
              </template>

              <template #item.sairam="{ item }">
                <v-chip
                  v-if="item.sairam > 0"
                  small
                  color="error"
                  label
                  text-color="white"
                  class="font-weight-bold"
                >
                  <v-icon left>mdi-account-arrow-up </v-icon>
                  {{ item.sairam }}
                </v-chip>
              </template>

              <template #item.created_at="{ item }">
                {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
              </template>

              <template #item.actions="{ index, item }">
                <v-btn
                  icon
                  small
                  color="primary"
                  :loading="loadingSync && loadingSyncIndex === index"
                  @click.stop="syncTags(item.id, index)"
                >
                  <v-icon> mdi-sync </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <DialogAddEditTagGroup
      v-if="dialogAddEditTagGroup"
      v-model="dialogAddEditTagGroup"
      :tag-group-id="tagGroupId"
      @refresh="getTagGroups"
    />
  </v-container>
</template>

<script>
import api from "@/api/axios_service";

export default {
  name: "TagsGroups",

  components: {
    DialogAddEditTagGroup: () => import("./DialogAddEditTagGroup.vue"),
  },

  data() {
    return {
      loading: false,
      loadingSync: false,
      loadingSyncIndex: null,
      tagGroups: [],
      search: "",
      dialogAddEditTagGroup: null,
      tagGroupId: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          width: "80px",
        },
        {
          text: "descricao",
          value: "descricao",
        },
        {
          text: "data inicio",
          value: "data_inicio",
          width: "120px",
        },
        {
          text: "data fim",
          value: "data_fim",
          width: "120px",
        },
        {
          text: "hora inicio",
          value: "hora_inicio",
          width: "120px",
        },
        {
          text: "hora fim",
          value: "hora_fim",
          width: "120px",
        },
        {
          text: "tipo",
          value: "tipo",
          width: "90px",
        },
        {
          text: "dashboard",
          value: "dashboard",
          width: "120px",
          align: "center",
          sortable: false,
        },
        {
          text: "entraram",
          value: "entraram",
          width: "120px",
        },
        {
          text: "sairam",
          value: "sairam",
          width: "120px",
        },
        {
          text: "criado em",
          value: "created_at",
          width: "150px",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
        },
      ];
    },
  },

  methods: {
    addTagGroup() {
      this.tagGroupId = null;
      this.dialogAddEditTagGroup = true;
    },

    editTagGroup(item) {
      this.tagGroupId = item.id;
      this.dialogAddEditTagGroup = true;
    },

    async syncTags(id, index) {
      if (this.loadingSync) return;

      this.loadingSyncIndex = index;
      this.loadingSync = true;
      await api.post(`/tags-groups/${id}/sync`);
      await this.getTagGroups();
      this.$toast.success("Tags sincronizadas com sucesso!");
      this.loadingSync = false;
    },

    async getTagGroups() {
      this.loading = true;

      const response = await api.get("/tags-groups");
      this.tagGroups = response.data.result;

      this.loading = false;
    },
  },

  mounted() {
    this.getTagGroups();
  },
};
</script>

<style></style>
